<template>
  <div class="PageBox">
    <van-nav-bar
            fixed
            :border="false"
            :title="$t('userInfo.default[0]')"
            left-arrow
            @click-left="$router.go(-1)"
    />
    <div class="ScrollBox">
      <van-form>
      <van-cell
        size="large" is-link
        :title="$t('userInfo.default[1]')"
        center
        @click="showHeader = true"
      >
        <div class="userFace">
          <img :src="`./static/head/${UserInfo.header}`" />
        </div>
      </van-cell>
        <div class="infoPanel">
        <van-cell
                size="large"
                :title="$t('userInfo.default[15]')"
                center
                :is-link="UserInfo.nickname ? false : true"
        >
          <template #right-icon>
            <van-field style="width:150px" v-model="UserInfo.nickname" :placeholder="$t('userInfo.default[8]')"/>
          </template>
        </van-cell>
          <div style="height: 1px;background-color: #115352;width: 90%;margin-left: 20px"></div>
      <van-cell
              size="large"
              :title="$t('userInfo.default[2]')"
              center
              :value="UserInfo.username ? UserInfo.username : $t('userInfo.default[8]')"
              :is-link="UserInfo.username ? false : true"
      />
          <div style="height: 1px;background-color: #115352;width: 90%;margin-left: 20px"></div>
          <van-cell
                size="large"
                :title="$t('userInfo.default[3]')"
                center
                :value="UserInfo.mail"
                :value-class="['']"
        />
          <div style="height: 1px;background-color: #115352;width: 90%;margin-left: 20px"></div>
<!--      <van-cell-->
<!--              size="large"-->
<!--              :title="$t('userInfo.default[3]')"-->
<!--              center-->
<!--              :value="UserInfo.username ? UserInfo.username : $t('userInfo.default[8]')"-->
<!--              :is-link="UserInfo.username ? false : true"-->
<!--      />-->
      <van-cell
              size="large"
              :title="$t('userInfo.default[16]')"
              center
              :value-class="['']"
      >
        <template>{{UserInfo.idcode}}
          <img :src="`./static/icon/copy.png`" class="copy-qb" @click="copyCode()" width="18"></img>
        </template>
      </van-cell>
          <div style="height: 1px;background-color: #115352;width: 90%;margin-left: 20px"></div>

          <van-cell
              size="large"
              :title="$t('userInfo.default[6]')"
              center
              :value="$t('userInfo.default[8]')"
              is-link
              @click="showPassWord = true"
      />
        </div>
      </van-form>
      <div style="text-align: center;margin-top: 20px">
        <p class="btn" @click="setUserInfo">{{ $t('userInfo.label[6]') }}</p>
      </div>
    </div>
    <van-action-sheet
      v-model="showHeader"
      :title="$t('userInfo.default[9]')"
      close-on-popstate
    >
      <van-radio-group v-model="radioHeader">
        <van-grid clickable icon-size="45" column-num="5">
          <van-grid-item
            :icon="`./static/head/head_${item}.png`"
            v-for="item in 40"
            :key="item"
            @click="selectHeader(item)"
          />
        </van-grid>
      </van-radio-group>
    </van-action-sheet>
    <van-action-sheet
      v-model="showPassWord"
      :title="$t('userInfo.default[10]')"
      close-on-popstate
    >
      <van-field
        v-model.trim="postData.o_password"
        type="password"
        :label="$t('userInfo.label[0]')"
        :placeholder="$t('userInfo.placeholder[0]')"
        clearable
        size="large"
      />
      <van-field
        v-model.trim="postData.n_password"
        type="password"
        :label="$t('userInfo.label[1]')"
        :placeholder="$t('userInfo.placeholder[1]')"
        clearable
        size="large"
      />
      <van-field
        v-model.trim="postData.r_password"
        type="password"
        :label="$t('userInfo.label[2]')"
        :placeholder="$t('userInfo.placeholder[2]')"
        clearable
        size="large"
      />
      <div style="text-align: left;padding: 5px">
        <div style="margin-left: 20px;color: #DAA78A;font-size: 16px">{{$t('resetpwd2[2]')}}</div>
      </div>
      <div style="padding: 6px;padding-bottom: 5px">
        <p class="btn" @click="setpwd">{{ $t("userInfo.default[12]") }}</p>
      </div>
    </van-action-sheet>
  </div>
</template>

<script>
import Clipboard from "clipboard";

export default {
  name: "Info",
  components: {},
  props: [],
  data() {
    return {
      showHeader: false,
      showPassWord: false,
      showPayWord: false,
      radioHeader: "",
      postData: {},
    };
  },
  computed: {},
  watch: {},
  created() {
    this.postData.header = this.UserInfo.header;
    this.$Model.GetUserInfo();
    console.log(this.UserInfo);
  },
  mounted() {},
  activated() {},
  destroyed() {},
  methods: {
    copyCode() {
      var that = this;
      var copyValue = this.UserInfo.idcode;
      let clipboard = new Clipboard('.copy-qb', {
        text: () => {
          return copyValue
        }
      })
      clipboard.on('success', () => {
        that.$Dialog.Toast(this.$t('recharge.label[5]'));
        clipboard.destroy()
      })
      clipboard.on('error', () => {
        that.$Dialog.Toast(this.$t('taskOrder2[3]'));
        clipboard.destroy()
      })
    },
    selectHeader(index) {
      this.postData.header = `head_${index}.png`;
      this.UserInfo.header  = `head_${index}.png`;
      if (this.showHeader) {
        this.showHeader = false;
      }
    },
    setpwd(){
      if (this.showPassWord) {
        this.showPassWord = false;
      }
    },
    setUserInfo() {
      this.postData['nickname'] = this.UserInfo.nickname
      debugger
      this.$Model.SetUserInfo(this.postData, (data) => {
        if (data.code == 1) {
          if (this.showHeader) {
            this.showHeader = false;
          }
          if (this.showPassWord) {
            this.showPassWord = false;
          }
          if (this.showPayWord) {
            this.showPayWord = false;
          }
          this.postData = {};
        }
      });
    },
    clearCache() {
      // localStorage.clear();
      // this.$Model.GetBackData();
      // this.$router.push("/login");
      this.$Model.Logout()
    },
  },
};
</script>
<style scoped>
  .PageBox {
    background-color: #0F4F4E;
  }

  .van-nav-bar {
    background-color: #0F4F4E;
  }
  .van-nav-bar>>>.van-nav-bar__title{
    color:#FFFEFF;
  }
  .van-nav-bar>>>.van-nav-bar__arrow {
    font-size: 20px;
    color:#FFFEFF;
  }

.van-cell__left-icon {
  width: 24px;
  height: 24px;
  margin-right: 10px;
}
.van-icon__image {
  width: 100%;
  height: 100%;
}
.van-cell__title {
  font-size: 14px;
  color: #000;
}

.infoPanel {
  margin-top: 10px;
  border-radius: 5px;
  background-color: #337F7B;
}

.ScrollBox {
  padding: 10px;
  color: #cccccc;
}
  .ScrollBox >>>.van-grid-item__content {
    padding: 10px !important;
    display: flex;
    background-color:#0F4F4E;
  }
  .van-cell>>>.van-icon{
    font-size: 19px;
    color: #89BFC1;
  }

.ScrollBox >>> .van-cell {
  order-radius: 10px;
  border-radius: 5px;
  background-color: #337F7B;
}
.ScrollBox >>> .van-cell::after {
  display: none;
}
.ScrollBox >>> .van-cell__title {
  font-size: 14px;
  font-family: Alibaba;
  font-weight: normal;
  color: #8AC0C0;
}

.ScrollBox >>> .van-cell__value {
  display: flex;
  color:#FFFEFF;
  justify-content: flex-end;
}
.ScrollBox >>> .userFace {
  width: 55px;
  height: 55px;
  background: #eff0f2;
  border-radius: 50%;
  padding: 6px;
  overflow: hidden;
}

  .van-cell >>> .van-field__control {
    color: #FFFEFF !important;
    padding-left: 7px;
    height: 100%;
  }

  .van-cell >>> .van-field__control::-webkit-input-placeholder {
    color: #e7e7e7;
    font-family: Alibaba;
  }

  .van-cell >>> .van-field__control::-moz-placeholder {
    color: #e7e7e7;
    font-family: Alibaba;
  }



  .van-action-sheet >>> .van-field__control {
    color: #ccc !important;
  }

  .van-action-sheet >>> .van-field__control::-webkit-input-placeholder {
    color: #ccc;
  }

  .van-action-sheet >>> .van-field__control::-moz-placeholder {
    color: #ccc;
  }

.ScrollBox >>> .userFace img {
  width: 100%;
}
.ScrollBox >>> .isTrue {
  color: #333;
  padding-right: 20px;
}
.van-cell:nth-child(4) >>> .van-cell__left-icon img {
  transform: scale(1.1);
}
.ScrollBox >>> .van-cell__value {
  font-size: 15px;
}

.btn {
  padding: 10px 10px;
  border-radius: 5px;
  background-color: #DAA78A;
  color: #FFFEFF;
  font-size: 18px;
  text-align: center;
  margin: 15px 10px 10px;
}
</style>